/* eslint import/no-webpack-loader-syntax: off */
// TODO: Look at putting this loader into my webpack.config.js file
import React, { useRef, useEffect } from "react";
import "./Map.css";
import { useDispatch, useSelector } from "react-redux";
import {
  updateLocation,
  selectLocation,
} from "../StateManagement/currentLocationSlice";
import MapLegendMobile from "../MapLegend/MapLegendMobile";
import MapLegend from "../MapLegend/MapLegend";
import useLocationList from "../Clients/useLocationList";
import { isMobile } from "../StateManagement/getMobileSite";

import mapboxgl from "!mapbox-gl";
import { selectStatistic } from "../StateManagement/statisticSourceSlice";

mapboxgl.accessToken = process.env.REACT_APP_MAP_BOX_TOKEN;

const Map = () => {
  const mapContainer = useRef(null);
  const map = useRef(null);

  const dispatch = useDispatch();

  const location = useSelector(selectLocation);
  const statistic = useSelector(selectStatistic);
  const [locations] = useLocationList();

  // use Effect for creating the map
  useEffect(() => {
    // Due to react strict mode, this will be initialized twice. To prevent side affects, remove current map before initializing.
    if (map.current) {
      map.current.remove();
      map.current = null;
    }

    const mapProperties = {
      container: mapContainer.current,
      style: statistic.mapStyleLink,
    };

    if (location.current && locations) {
      const locationProps = locations.getLocation(location.current.id);
      mapProperties["center"] = [
        locationProps.longitude,
        locationProps.latitude,
      ];
      mapProperties["zoom"] = 8;
    }

    map.current = new mapboxgl.Map(mapProperties);

    if (isMobile()) {
      map.current.setZoom(2);
      map.current.setCenter([-95.4205, 39.8283]);
    }

    map.current.on("mouseover", "costofliving", () => {
      map.current.getCanvas().style.cursor = "pointer";
    });
    map.current.on("mouseleave", "costofliving", () => {
      map.current.getCanvas().style.cursor = "";
    });

    map.current.on("click", (event) => {
      const points = map.current.queryRenderedFeatures(event.point, {
        layers: ["costofliving"],
      });

      if (points.length > 0) {
        dispatch(updateLocation(points[0].properties));
      } else {
        dispatch(updateLocation(null));
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statistic, dispatch]);

  // Use Effect for updating map
  useEffect(() => {
    if (!map || !map.current) return;

    if (location.current && locations) {
      const locationProps = locations.getLocation(location.current.id);
      map.current.setCenter([locationProps.longitude, locationProps.latitude]);
      map.current.setZoom(8);
    }
  }, [location, locations]);

  return (
    <div
      key={statistic.statistic}
      ref={mapContainer}
      style={{
        width: "inherit",
        height: "inherit",
        position: "absolute",
        padding: 0,
      }}
    >
      {isMobile() ? (
        <div style={{ position: "absolute", top: "60%", left: "2%" }}>
          <MapLegendMobile></MapLegendMobile>
        </div>
      ) : (
        <div style={{ position: "absolute", top: "55%", left: "5%" }}>
          <MapLegend></MapLegend>
        </div>
      )}
    </div>
  );
};

Map.propTypes = {};

Map.defaultProps = {};

export default Map;
