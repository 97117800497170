import React from "react";
import { isMobile } from "./StateManagement/getMobileSite";
import Mobile from "./mobile";
import Desktop from "./desktop";
import { useDispatch } from "react-redux";
import {
  updateStatisticSource,
} from "./StateManagement/statisticSourceSlice";
import { statistics } from "./Data/statisticSource";

const Home = ({statisticKey}) => {

  const dispatch = useDispatch();
  dispatch(updateStatisticSource(statistics[statisticKey]));

  if (isMobile()) {
    return <Mobile></Mobile>;
  } else {
    return <Desktop></Desktop>;
  }
};

export default Home;
