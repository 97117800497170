import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { statistics } from "../Data/statisticSource";

const Footer = () => {
  return (
    <footer className="bg-dark text-light">
      <Container>
        <Row>
          <Col md={4}>
            <h5>USA Cost Watch</h5>
            <ul className="list-unstyled">
              <li>
                <a href="/data-sources">Data Sources</a>
              </li>
            </ul>
          </Col>
          <Col md={4}>
          <h5>Pages</h5>
            <ul className="list-unstyled">
              {Object.keys(statistics).map((statKey) => (
                <li>
                  <a href={`/${statKey}`}>{statKey}</a>
                </li>
              ))}
            </ul>
          </Col>
          <Col md={4}></Col>
        </Row>
        <Row>
          <Col className="text-center mt-3">
            <p>&copy; {new Date().getFullYear()} USA Cost Watch</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
