import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Provider } from "react-redux";
import store from "./store";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./home";
import DataSources from "./dataSources";
import { statistics } from "./Data/statisticSource";
import NotFound from "./404";


const statisticsRoutes = Object.keys(statistics).map((statKey) => ({
  path: `/${statKey}`,
  element: <Home statisticKey={statKey} />,
}));

const routes = [
  {
    path: "/",
    element: <Home statisticKey={Object.keys(statistics)[0]} />,
  },
  {
    path: "/data-sources",
    element: <DataSources />,
  },
  {
    path: "*",
    element: <NotFound/>,
  },
].concat(statisticsRoutes);

const router = createBrowserRouter(routes);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);
