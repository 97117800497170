import useLegend from "../Clients/useLegend";

const MapLegendArrow = () => {
  const [data] = useLegend();

  if (!data) {
    return null;
  }

  const colors = data
    .sort((a, b) => b.value - a.value)
    .map((colorEntry) => colorEntry.color);

  return (
    <svg viewBox="20 0 130 200">
      <defs>
        <linearGradient id="legendGradient" gradientTransform="rotate(90)">
          {colors.map((color, index) => (
            <stop
              key={index}
              offset={`${(index / (colors.length - 1)) * 100}%`}
              style={{ stopColor: color, stopOpacity: 1 }}
            />
          ))}
        </linearGradient>
      </defs>
      <text x="30" y="22" fill="black" fontSize="18px">
        High
      </text>

      <text x="30" y="192" fill="black" fontSize="18px">
        Low
      </text>
      <path
        d="M50,60 L100,0 L150,60 L125,60 L125,200 L75,200  L75,60 Z"
        fill="url(#legendGradient)"
      />
    </svg>
  );
};

export default MapLegendArrow;
