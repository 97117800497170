import React, { useState } from "react";
import MapDetails from "./MapDetails/MapDetails";
import Map from "./Map/Map";
import Button from "react-bootstrap/Button";
import Footer from "./Footer/Footer";
import MapDetail from "./MapDetails/MapDetail/MapDetail";
import { selectLocation } from "./StateManagement/currentLocationSlice";
import { useSelector } from "react-redux";
import { Container, Navbar, Row, Col, Table } from "react-bootstrap";
import useLocationList from "./Clients/useLocationList";
import DataDropDown from "./DataDropDown/dataDropDown";
import { selectStatistic } from "./StateManagement/statisticSourceSlice";
import { isReactSnap } from "./StateManagement/isReactSnap";

const Mobile = () => {
  let [displayMap, setDisplayMap] = useState(!isReactSnap());

  const simpleCurrentLocation = useSelector(selectLocation).current;
  const statistic = useSelector(selectStatistic);
  const [locations] = useLocationList();

  let currentLocation = null;
  if (simpleCurrentLocation != null && locations != null)
    currentLocation = locations.getLocation(simpleCurrentLocation.id);

  return (
    <div>
      <Navbar
        bg="info"
        expand="lg"
        style={{ position: "sticky", top: "0", zIndex: 300 }}
      >
        <Container>
          <Row>
            <Col>
              <Navbar.Brand href="">USA Cost Watch</Navbar.Brand>
            </Col>
            <Col style={{ textAlign: "right" }}>
              <Button
                variant="primary"
                onClick={() => {
                  setDisplayMap(!displayMap);
                }}
              >
                {displayMap ? "List" : "Map"}
              </Button>
            </Col>
            <Col className="d-flex justify-content-center">
              <DataDropDown></DataDropDown>
            </Col>
          </Row>
        </Container>
      </Navbar>
      <Container
        fluid
        style={{
          height: "100vh",
          width: "100vw",
          overflowX: "hidden",
          paddingRight: "0",
          paddingLeft: "0",
        }}
      >
        {displayMap && <Map />}
        {!displayMap && <MapDetails />}
      </Container>
      {displayMap && currentLocation && (
        <Table
          style={{
            borderRadius: "100px",
            borderStyle: "solid",
            borderWidth: "thick",
            position: "fixed",
            bottom: 0,
            left: 0,
          }}
        >
          {MapDetail(
            currentLocation,
            statistic.statistic,
            statistic.preUnit,
            statistic.postUnit,
            null,
            true,
            false,
            true
          )}{" "}
        </Table>
      )}
      <br></br>
      <br></br>
      <br></br>
      <Footer></Footer>
    </div>
  );
};

export default Mobile;
